var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white", attrs: { id: "counter" } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-center pt-2 text-center" },
      [
        _c("div", [
          _c("h5", { staticClass: "colorWhite text-uppercase mb-0" }, [
            _vm._v(_vm._s(_vm.sitename))
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "hideLandscape pt-2 pb-2 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "text-center" }, [
          _c(
            "p",
            {
              staticClass: "display-1 mb-0",
              style: "color:" + _vm.displayColor + ";"
            },
            [_vm._v(_vm._s(_vm.activeCounter))]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mb-0 color-white" }, [
            _vm._v(
              " " +
                _vm._s(_vm.vueLang.get("main.capacidade")) +
                ": " +
                _vm._s(_vm.maxpeople - _vm.activeCounter) +
                " "
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hideLandscape" }, [
      _vm.aboveMax
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.vueLang.get("main.lotacaoExcedida")) +
                " "
            ),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm.vueLang.get("main.naoDeixeEntrarMaisPessoas")) +
                "!"
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.numValid
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v(_vm._s(_vm.vueLang.get("main.numeroInvalido")))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "portraitReverse row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "col-12 px-0 pt-4" }, [
          _c("div", { staticClass: "hidePortrait" }, [
            _vm.aboveMax
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.vueLang.get("main.lotacaoExcedida")) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.vueLang.get("main.naoDeixeEntrarMaisPessoas")
                      ) +
                      "!"
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.numValid
              ? _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v(_vm._s(_vm.vueLang.get("main.numeroInvalido")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "hidePortrait pt-2 pb-2 d-flex justify-content-center"
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "p",
                  {
                    staticClass: "display-1 mb-0",
                    style: "color:" + _vm.displayColor + ";"
                  },
                  [_vm._v(_vm._s(_vm.activeCounter))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mb-0 color-white" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.vueLang.get("main.capacidade")) +
                      ": " +
                      _vm._s(_vm.maxpeople - _vm.activeCounter) +
                      " "
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 px-0 text-center" }, [
            _c("small", { staticClass: "smallDate text-center" }, [
              _vm._v(" " + _vm._s(_vm.todayDate) + " ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "text-center", attrs: { width: "100%" } },
            [
              _c(
                "tbody",
                _vm._l(_vm.logsDisplay, function(log, index) {
                  return _c("tr", [
                    _c("td", [
                      log.movement_type === "reset"
                        ? _c("span", [_vm._m(0, true)])
                        : _c("span", [
                            log.movement_type === "enter"
                              ? _c("i", {
                                  staticClass: "mdi mdi-arrow-up color-success"
                                })
                              : _c("i", {
                                  staticClass: "mdi mdi-arrow-down color-danger"
                                })
                          ])
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.formatDate(log.created_at)) + " ")
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      log.movement_type === "reset"
                        ? _c("span", [
                            _c("span", { staticClass: "color-warning" }, [
                              _vm._v(" - ")
                            ])
                          ])
                        : _c("span", [
                            log.movement_type === "enter"
                              ? _c("span", { staticClass: "color-success" }, [
                                  _vm._v(" + ")
                                ])
                              : _c("span", { staticClass: "color-danger" }, [
                                  _vm._v(" - ")
                                ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(log.people_amount) +
                                "\n                        "
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(_vm.getDevice(log)) + " ")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(" " + _vm._s(log.people_n) + " ")]),
                    _vm._v(" "),
                    _vm._m(1, true),
                    _vm._v(" "),
                    _c("td", [
                      _vm.showRollBackButton && log.id === _vm.myLastLogId
                        ? _c("i", {
                            staticClass: "mdi mdi-history color-danger",
                            on: {
                              click: function($event) {
                                return _vm.rollBack()
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "py-3 text-capitalize" }, [
            _c(
              "a",
              {
                staticClass: "smallDate",
                attrs: { href: _vm.vueRoute("stats.historic") }
              },
              [_c("small", [_vm._v(_vm._s(_vm.vueLang.get("main.historico")))])]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "py-3 d-flex justify-content-center" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger colorWhite",
                staticStyle: {
                  display: "flex",
                  "align-self": "center",
                  "margin-right": "30px"
                },
                on: { click: _vm.clearInputValue }
              },
              [_vm._v("\n                        C\n                    ")]
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.peopleAmount,
                expression: "peopleAmount"
              }
            ],
            staticClass: "form-control form-control-lg number-input",
            attrs: {
              type: "number",
              min: "1",
              id: "peopleAmount",
              placeholder: "1"
            },
            domProps: { value: _vm.peopleAmount },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.peopleAmount = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mx-auto", attrs: { id: "numberPad" } }, [
          _c(
            "div",
            {
              staticClass: "numberPad1",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "1"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("1")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad2",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "2"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("2")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad3",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "3"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("3")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad4",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "4"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("4")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad5",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "5"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("5")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad6",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "6"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("6")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad7",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "7"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("7")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad8",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "8"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("8")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad9",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "9"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v("9")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPadOut",
              class: { "pointer-events-none": !_vm.canClick },
              on: { click: _vm.decrementValueB }
            },
            [
              _c("span", { staticClass: "eachPadNumber eachPadNumberOut" }, [
                _vm._v("OUT")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPad0",
              on: {
                click: function($event) {
                  _vm.peopleAmount += "0"
                }
              }
            },
            [_c("span", { staticClass: "eachPadNumber" }, [_vm._v(" 0 ")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "numberPadIn",
              class: { "pointer-events-none": !_vm.canClick },
              on: { click: _vm.incrementValueB }
            },
            [
              _c("span", { staticClass: "eachPadNumber eachPadNumberIn" }, [
                _vm._v("IN")
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "color-warning" }, [
      _c("i", { staticClass: "mdi mdi-refresh-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("i", { staticClass: "mdi mdi-cellphone" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex visibility-hidden" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-warning colorWhite",
          staticStyle: {
            display: "flex",
            "align-self": "center",
            "margin-left": "30px"
          }
        },
        [_c("i", { staticClass: "mdi mdi-arrow-expand" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }