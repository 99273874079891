var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white", attrs: { id: "counter" } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-center pt-2 text-center pb-4" },
      [
        _c("div", [
          _c("h5", { staticClass: "colorWhite text-uppercase mb-0" }, [
            _vm._v(_vm._s(_vm.sitename))
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-6 px-0 pt-1 pb-4-landscape" }, [
        _c(
          "div",
          {
            staticClass: "pt-2 pb-2 d-flex justify-content-center hidePortrait"
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "p",
                {
                  staticClass: "display-1 mb-0",
                  style: "color:" + _vm.displayColor + ";"
                },
                [_vm._v(_vm._s(_vm.activeCounter))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0 color-white" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.vueLang.get("main.capacidade")) +
                    ": " +
                    _vm._s(_vm.maxpeople - _vm.activeCounter) +
                    " "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 px-0 text-center" }, [
          _c("small", { staticClass: "smallDate text-center" }, [
            _vm._v(" " + _vm._s(_vm.todayDate) + " ")
          ])
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "text-center", attrs: { width: "100%" } }, [
          _c(
            "tbody",
            _vm._l(_vm.logsDisplay, function(log, index) {
              return _c("tr", [
                _c("td", [
                  log.movement_type === "reset"
                    ? _c("span", [_vm._m(0, true)])
                    : _c("span", [
                        log.movement_type === "enter"
                          ? _c("i", {
                              staticClass: "mdi mdi-arrow-up color-success"
                            })
                          : _c("i", {
                              staticClass: "mdi mdi-arrow-down color-danger"
                            })
                      ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.formatDate(log.created_at)) + " ")
                ]),
                _vm._v(" "),
                _c("td", [
                  log.movement_type === "reset"
                    ? _c("span", [
                        _c("span", { staticClass: "color-warning" }, [
                          _vm._v(" - ")
                        ])
                      ])
                    : _c("span", [
                        log.movement_type === "enter"
                          ? _c("span", { staticClass: "color-success" }, [
                              _vm._v(" + ")
                            ])
                          : _c("span", { staticClass: "color-danger" }, [
                              _vm._v(" - ")
                            ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(log.people_amount) +
                            "\n                        "
                        )
                      ])
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(_vm.getDevice(log)) + " ")]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(log.people_n) + " ")]),
                _vm._v(" "),
                _vm._m(1, true),
                _vm._v(" "),
                _c("td", [
                  _vm.showRollBackButton && log.id === _vm.myLastLogId
                    ? _c("i", {
                        staticClass: "mdi mdi-history color-danger",
                        on: {
                          click: function($event) {
                            return _vm.rollBack()
                          }
                        }
                      })
                    : _vm._e()
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "py-3 text-capitalize" }, [
          _c(
            "a",
            {
              staticClass: "smallDate",
              attrs: { href: _vm.vueRoute("stats.historic") }
            },
            [_c("small", [_vm._v(_vm._s(_vm.vueLang.get("main.historico")))])]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 pt-4-landscape" }, [
        _c(
          "div",
          {
            staticClass: "pt-2 pb-2 d-flex justify-content-center hideLandscape"
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "p",
                {
                  staticClass: "display-1 mb-0",
                  style: "color:" + _vm.displayColor + ";"
                },
                [_vm._v(_vm._s(_vm.activeCounter))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0 color-white" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.vueLang.get("main.capacidade")) +
                    ": " +
                    _vm._s(_vm.maxpeople - _vm.activeCounter) +
                    " "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 px-0 mb-5" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-success btn-block",
              class: { "pointer-events-none": !_vm.canClick },
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
              attrs: { id: "enterButton" },
              on: { click: _vm.incrementValueB }
            },
            [
              _vm._v("\n                    IN "),
              _c("i", {
                staticClass: "mdi mdi-arrow-up pl-1",
                staticStyle: { "font-size": "20px" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 px-0" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-danger btn-block",
              class: { "pointer-events-none": !_vm.canClick },
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
              attrs: { id: "exitButton" },
              on: { click: _vm.decrementValueB }
            },
            [
              _vm._v("\n                    OUT "),
              _c("i", {
                staticClass: "mdi mdi-arrow-down pl-1",
                staticStyle: { "font-size": "20px" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _vm.aboveMax
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.vueLang.get("main.lotacaoExcedida")) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.vueLang.get("main.naoDeixeEntrarMaisPessoas")) +
                    "!"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.numValid
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _vm._v(_vm._s(_vm.vueLang.get("main.numeroInvalido")))
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "color-warning" }, [
      _c("i", { staticClass: "mdi mdi-refresh-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("i", { staticClass: "mdi mdi-cellphone" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }