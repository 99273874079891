var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profiles
    ? _c("p", { staticClass: "mb-0" }, [
        _c(
          "span",
          {
            staticClass: "text-muted",
            staticStyle: { position: "relative", top: "3px", display: "flex" }
          },
          [
            _c(
              "span",
              {
                class: { "text-muted-plus": _vm.profiles.counter == 0 },
                staticStyle: { display: "flex", "align-items": "center" }
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "16px", "padding-right": "5px" }
                  },
                  [_vm._v(_vm._s(_vm.profiles.counter))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "mdi mdi-cellphone pr-2" })
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                class: { "text-muted-plus": _vm.profiles.camera == 0 },
                staticStyle: { display: "flex", "align-items": "center" }
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "16px", "padding-right": "5px" }
                  },
                  [_vm._v(_vm._s(_vm.profiles.camera) + " ")]
                ),
                _c("i", { staticClass: "mdi mdi-video pr-2" })
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                class: { "text-muted-plus": _vm.profiles.monitor == 0 },
                staticStyle: { display: "flex", "align-items": "center" }
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "16px", "padding-right": "5px" }
                  },
                  [_vm._v(_vm._s(_vm.profiles.monitor))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "mdi mdi-monitor" })
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }