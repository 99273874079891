import Vue from 'vue';
import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect);

import adminCounter from './vue/components/adminCounter';
Vue.component('admin-counter', adminCounter);

import counter from './vue/components/counter';
Vue.component('counter', counter);

import counterSmall from './vue/components/counterSmall';
Vue.component('counter-small', counterSmall);

import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

import vue2Dropzone from "vue2-dropzone";
Vue.component('vue-dropzone', vue2Dropzone);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import SiteProfiles from './vue/components/siteprofiles';
Vue.component('siteprofiles', SiteProfiles);

Vue.prototype.$http = axios
