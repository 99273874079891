window.Vue = require('vue');

Vue.prototype.$momentJS = momentJS;
switch (Lang.getLocale()){
    case 'pt':
        Vue.prototype.$multiselectPlaceholderSelect = 'Pressione para selecionar';
        Vue.prototype.$multiselectPlaceholderRemove = 'Pressione para remover';
        Vue.prototype.$multiselectPlaceholderRemoveNotAllowEmpty = 'Não é permitido remover';
        break;

    case 'en':
        Vue.prototype.$multiselectPlaceholderSelect = 'Press to select';
        Vue.prototype.$multiselectPlaceholderRemove = 'Press to remove';
        Vue.prototype.$multiselectPlaceholderRemoveNotAllowEmpty = 'It is not allowed to remove';
        break;

    case 'es':
        Vue.prototype.$multiselectPlaceholderSelect = 'Presione para seleccionar';
        Vue.prototype.$multiselectPlaceholderRemove = 'Presione para quitar';
        Vue.prototype.$multiselectPlaceholderRemoveNotAllowEmpty = 'No esta permitido quitar';
        break;

    case 'fr':
        Vue.prototype.$multiselectPlaceholderSelect = 'Appuyez pour sélectionner';
        Vue.prototype.$multiselectPlaceholderRemove = 'Appuyez pour retirer';
        Vue.prototype.$multiselectPlaceholderRemoveNotAllowEmpty = "Il n'est pas permis de supprimer";
        break;

    default:
        Vue.prototype.$multiselectPlaceholderSelect = 'Pressione para selecionar';
        Vue.prototype.$multiselectPlaceholderRemove = 'Pressione para remover';
        Vue.prototype.$multiselectPlaceholderRemoveNotAllowEmpty = 'Não é permitido remover';
}



Vue.mixin({
    methods:{
        vueRoute(...args){
            return route(...args);
        },

        qrLoginFunction(device_id){
            $('#loginQrForm').append('<input type="hidden" name="device_id" value="'+ device_id +'"/>');
            setTimeout(()=>{
                $('#loginQrForm').submit();
            }, 100);
        },

        getEstimatedWaitTime(){
            return axios.get(route('counter.getEstimatedWaitTime')).then((response) => {
                this.estimatedWaitTime = response.data;
            });
        },

        startListeners(){
            Echo.channel('test')
                .listen('CounterChange', e => {
                    this.listenChanges(e.return);
                })

            Echo.channel('people_counter_database_test')
                .listen('CounterChange', e => {
                    this.listenChanges(e.return);
                })

            Echo.channel('stage_afluencia_database_test')
                .listen('CounterChange', e => {
                    this.listenChanges(e.return);
                })

            Echo.channel('afluencia_database_test')
                .listen('CounterChange', e => {
                    this.listenChanges(e.return);
                })
        },
    },
});

Vue.directive('lowercase', {
    update (el) {
        el.value = el.value.toLowerCase()
    },
})

Vue.directive('nospace', {
    update (el) {
        el.value = el.value.replace(/\s/g , '');
    },
})

require('./register-components-vue.js');

