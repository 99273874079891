<template>
    <p class="mb-0" v-if="profiles">
        <span class="text-muted" style="position:relative; top: 3px; display: flex;">
            <span style="display: flex; align-items: center;" v-bind:class="{'text-muted-plus': profiles.counter == 0}">
                <span style="font-size: 16px; padding-right: 5px;">{{ profiles.counter }}</span> <i class="mdi mdi-cellphone pr-2"></i>
            </span>

            <span style="display: flex; align-items: center;" v-bind:class="{'text-muted-plus': profiles.camera == 0}">
                <span style="font-size: 16px; padding-right: 5px;">{{ profiles.camera }} </span><i class="mdi mdi-video pr-2"></i>
            </span>

            <span style="display: flex; align-items: center;" v-bind:class="{'text-muted-plus': profiles.monitor == 0}">
                <span style="font-size: 16px; padding-right: 5px;">{{ profiles.monitor }}</span> <i class="mdi mdi-monitor"></i>
            </span>
        </span>
    </p>
</template>

<script>
    export default {
        name: 'SiteProfiles',

        data(){
            return{
                profiles: undefined,
            }
        },

        props: {
            site: {
                type: Object,
                required: true,
            },
        },

        mounted() {
            this.getInfo();
        },

        methods:{
            getInfo(){
                return axios.get(route('api.components.siteprofiles.getInfo', this.site.id)).then((response) => {
                    this.profiles = response.data;
                    return 'ok';
                });
            },
        }
    }
</script>
