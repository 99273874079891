<template>
    <div id="counter" class="white">
        <div class="d-flex justify-content-center pt-2 text-center">
            <div>
                <h5 class="colorWhite text-uppercase mb-0">{{ sitename }}</h5>
            </div>
        </div>

        <div class="hideLandscape pt-2 pb-2 d-flex justify-content-center">
            <div class="text-center">
                <p class="display-1 mb-0" :style="`color:${displayColor};`">{{activeCounter}}</p>
                <p class="mb-0 color-white"> {{vueLang.get('main.capacidade')}}: {{maxpeople - activeCounter}} </p>
            </div>
        </div>

        <div class="hideLandscape">
            <div v-if="aboveMax" class="alert alert-danger">
                {{vueLang.get('main.lotacaoExcedida')}} <br>
                {{vueLang.get('main.naoDeixeEntrarMaisPessoas')}}!</h5>
            </div>
            <div v-if="numValid" class="alert alert-warning">{{vueLang.get('main.numeroInvalido')}}</div>
        </div>

        <div class="portraitReverse row">
            <div class="col-md-6">
                <div class="col-12 px-0 pt-4">
                    <div class="hidePortrait">
                        <div v-if="aboveMax" class="alert alert-danger">
                            {{vueLang.get('main.lotacaoExcedida')}} <br>
                            {{vueLang.get('main.naoDeixeEntrarMaisPessoas')}}!</h5>
                        </div>
                        <div v-if="numValid" class="alert alert-warning">{{vueLang.get('main.numeroInvalido')}}</div>
                    </div>

                    <div class="hidePortrait pt-2 pb-2 d-flex justify-content-center">
                        <div class="text-center">
                            <p class="display-1 mb-0" :style="`color:${displayColor};`">{{activeCounter}}</p>
                            <p class="mb-0 color-white"> {{vueLang.get('main.capacidade')}}: {{maxpeople - activeCounter}} </p>
                        </div>
                    </div>

                    <div class="col-12 px-0 text-center">
                        <small class="smallDate text-center"> {{todayDate}} </small>
                    </div>
                    <table class="text-center" width="100%">
                        <tbody>
                        <tr v-for="(log, index) in logsDisplay">
                            <td>
                            <span v-if="log.movement_type === 'reset'">
                                <span class="color-warning"><i class="mdi mdi-refresh-circle"></i></span>
                            </span>

                                <span v-else>
                                <i v-if="log.movement_type === 'enter'" class="mdi mdi-arrow-up color-success"></i>
                                <i v-else class="mdi mdi-arrow-down color-danger"></i>
                            </span>
                            </td>

                            <td> {{formatDate(log.created_at)}} </td>
                            <td>
                            <span v-if="log.movement_type === 'reset'">
                                <span class="color-warning"> - </span>
                            </span>

                            <span v-else>
                                <span class="color-success" v-if="log.movement_type === 'enter'"> + </span>
                                <span class="color-danger" v-else> - </span>
                                {{log.people_amount}}
                            </span>
                            </td>
                            <td> {{getDevice(log)}} </td>
                            <td> {{log.people_n}} </td>
                            <td> <i class="mdi mdi-cellphone"></i> </td>
                            <td> <i @click="rollBack()" v-if="showRollBackButton && log.id === myLastLogId" class="mdi mdi-history color-danger"></i> </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="py-3 text-capitalize">
                        <a :href="vueRoute('stats.historic')" class="smallDate"><small>{{vueLang.get('main.historico')}}</small></a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="py-3 d-flex justify-content-center">
                    <div class="d-flex">
                        <button class="btn btn-outline-danger colorWhite" @click="clearInputValue" style="display: flex; align-self: center; margin-right: 30px;">
                            C
                        </button>
                    </div>

                    <input
                        class="form-control form-control-lg number-input"
                        v-model="peopleAmount"
                        type="number"
                        min="1"
                        readonly
                        id="peopleAmount"
                        placeholder="1"/>


                    <div class="d-flex visibility-hidden">
                        <button class="btn btn-outline-warning colorWhite" style="display: flex; align-self: center; margin-left: 30px;">
                            <i class="mdi mdi-arrow-expand"></i>
                        </button>
                    </div>
                </div>

                <div id="numberPad" class="mx-auto">
                    <div @click="peopleAmount += '1'" class="numberPad1"> <span class="eachPadNumber">1</span> </div>
                    <div @click="peopleAmount += '2'" class="numberPad2"> <span class="eachPadNumber">2</span> </div>
                    <div @click="peopleAmount += '3'" class="numberPad3"> <span class="eachPadNumber">3</span> </div>
                    <div @click="peopleAmount += '4'" class="numberPad4"> <span class="eachPadNumber">4</span> </div>
                    <div @click="peopleAmount += '5'" class="numberPad5"> <span class="eachPadNumber">5</span> </div>
                    <div @click="peopleAmount += '6'" class="numberPad6"> <span class="eachPadNumber">6</span> </div>
                    <div @click="peopleAmount += '7'" class="numberPad7"> <span class="eachPadNumber">7</span> </div>
                    <div @click="peopleAmount += '8'" class="numberPad8"> <span class="eachPadNumber">8</span> </div>
                    <div @click="peopleAmount += '9'" class="numberPad9"> <span class="eachPadNumber">9</span> </div>
                    <div @click="decrementValueB" v-bind:class="{'pointer-events-none': !canClick}" class="numberPadOut"> <span class="eachPadNumber eachPadNumberOut">OUT</span> </div>
                    <div @click="peopleAmount += '0'" class="numberPad0"> <span class="eachPadNumber"> 0 </span> </div>
                    <div @click="incrementValueB" v-bind:class="{'pointer-events-none': !canClick}" class="numberPadIn"> <span class="eachPadNumber eachPadNumberIn">IN</span> </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "counter",
    props: {
        maxpeople: {
            type: Number,
            required: true
        },

        yellowWarning: {
            type: Number,
            required: true
        },

        counter: {
            type: Number,
            required: true
        },

        site: {
            type: Number,
            required: true
        },

        sitename: {
            type: String,
            required: true
        },

        stat: {
            type: Number
        },

        //Enter = 1 -- Exit = 0
        stype: {
            type: Number
        },

        profile: {
            required: true,
        },

        logs: {
            type: Array,
            required: false,
            default: [],
        },
    },

    data() {
        let self = this;
        return {
            activeCounter: undefined,
            isDisabled: false,
            isDisabledD: false,

            peopleAmount: '',
            aboveMax: false,
            stat1: undefined,
            stype1: undefined,
            leftPeople: undefined,
            numValid : false,
            displayColor: '#67e122',
            logsDisplay: undefined,
            todayDate: momentJS().format('dddd, DD [de] MMMM  [de] yyyy'),

            //CHECK OTHER LOGINS TIMER
            loginTimer: undefined,
            loginStatus: 'Ok',

            //ROLLBACK BUTTON TIMER
            timer: undefined,
            showRollBackButton: false,

            //Cameras timer
            camerasTimer: undefined,

            myLastLogId: undefined,

            canClick: true,

            vueLang: Lang,
        };
    },

    watch:{
        activeCounter(activeCounter){
            this.checkColor(activeCounter);
        },
    },

    mounted() {
        this.activeCounter = this.counter;
        this.stat1 = this.stat;
        this.stype1 = this.stype;

        this.logsDisplay = this.logs;

        this.startLoginsTimer();

        //this.startCamerasTimer();

        //this.startCounter();
        this.checkPeople();
        this.startListeners();

        this.checkColor(this.activeCounter);

        if (this.activeCounter == 0) {
            this.isDisabledD = true;
        }
    },

    created() {

    },

    methods: {
        /*swapCalculator(){
            axios.post(route('counter.changeCalculator')).then((response) => {
               location.reload();
            });
        },*/

        checkColor(activeCounter){
            if(activeCounter >= this.maxpeople ){
                this.aboveMax = true;
                this.displayColor = '#dc3545';
            } else if(activeCounter >= this.yellowWarning){
                this.aboveMax = false;
                this.displayColor = '#ffc107'
            } else{
                this.aboveMax = false;
                this.displayColor = '#67e122'
            }
        },

        clearInputValue(){
            this.peopleAmount = '';
        },

        formatDate(date){
            console.log(date)
            return momentJS(date, 'DD-MM-YYYY HH:mm:ss').format('LTS');
        },

        loadData() {
          axios.get(route("counter.show1", this.site)).then(response => {
            console.log((this.activeCounter = response.data));
          });
        },

        listenChanges(data){
            if(data.site.id === this.site){
                this.activeCounter = data.count;
                this.logsDisplay = data.logs;
            }
        },

        //The important stuff
        async incrementValueB() {
            this.canClick = false;

            if(!this.peopleAmount || this.peopleAmount == '' || this.peopleAmount == null){
                this.peopleAmount = 1;

            } else if (this.peopleAmount <= 0) {
                this.numValid = true;
                this.canClick = true;
                return;
            }

            this.numValid = false;

            await this.axiosIncrement();

            // this.startCounter();
            //this.isDisabled = true;
            //this.activeCounter += Number(this.peopleAmount);

            this.canClick = true;
        },

        //verify value before submitting to stats
        async decrementValueB() {

           this.canClick = false;

            if(!this.peopleAmount || this.peopleAmount == '' || this.peopleAmount == null){
                this.peopleAmount = 1;

            } else if (this.peopleAmount <= 0) {
                this.numValid = true;
                this.canClick = true;
                return;
            }

            this.numValid = false;

            // this.startCounter();
            //this.isDisabledD = true;
            //this.leftPeople = this.peopleAmount;
            //this.activeCounter -= Number(this.peopleAmount);

            //if the input number isn't superior to the current amount of people inside
            if (this.activeCounter - this.peopleAmount >= 0) {
                await this.axiosDecrement();
            } else{
                toastError("Esta a tentar remover mais pessoas do que aquelas que estão dentro");
            }

            this.canClick = true;
        },

        checkPeople() {
            if (this.activeCounter >= this.maxpeople) {
                this.aboveMax = true;
            } else {
                this.aboveMax = false;
            }
        },

        rollBack(){
            let log = this.logsDisplay[0];
            this.peopleAmount = log.people_amount;

            axios.post(route('counter.rollbackCounter', log.id)).then(()=>{
               this.showRollBackButton = false;
            });
        },

        async axiosIncrement(){
            return axios.post(route("counter.addB", this.site + "/" + this.peopleAmount))
            .then(
                response => {
                    this.stat1 = Number(this.peopleAmount);
                    this.stype1 = 1;
                    this.isDisabled = false;
                    this.isDisabledD = false;
                    this.peopleAmount = '';
                    this.checkPeople();
                    this.startCounter();

                    //GRAVAR O ID DO ULTIMO LOG PARA O ROLLBACK
                    this.myLastLogId = this.logsDisplay[0].id;

                    return 'ok';
                },
                error => {
                    console.log(error);
                }
            );
        },

        async axiosDecrement(){
            return axios.post(route("counter.removeB", this.site + "/" + this.peopleAmount))
            .then(
                response => {
                    this.stype1 = 0;
                    console.log("amouint " + this.peopleAmount);
                    console.log("left " + this.leftPeople);
                    console.log("ac " + this.activeCounter);

                    if (this.activeCounter == 0) {
                        console.log("disabled");
                        this.isDisabledD = true;
                        this.stat1 = Number(this.leftPeople);
                    } else {
                        this.isDisabledD = false;
                        this.stat1 = Number(this.peopleAmount);
                    }

                    this.peopleAmount = '';
                    this.checkPeople();
                    this.startCounter();

                    //GRAVAR O ID DO ULTIMO LOG PARA O ROLLBACK
                    this.myLastLogId = this.logsDisplay[0].id;

                    return 'ok';
                },
                error => {
                    console.log(error);
                }
            );
        },

        startCounter() {
            clearInterval(this.timer);
            this.showRollBackButton = true;

            function pad(val) {
                return val > 9 ? val : "0" + val;
            }

            let sec = 0;
            this.timer = setInterval(() => {
                if (!sec) {
                    let sec = 0;
                }

                pad(++sec % 60);

                if (sec === 30) {
                    this.showRollBackButton = false;
                }
            }, 1000);
        },

        startLoginsTimer() {
            clearInterval(this.loginTimer);

            function pad(val) {
                return val > 9 ? val : "0" + val;
            }

            let sec = 0;
            this.loginTimer = setInterval(async () => {
                if (!sec) {
                    let sec = 0;
                }

                pad(++sec % 60);

                if (sec === 60) {
                    await this.checkIfLoginIsValid();

                    if(this.loginStatus !== 'Ok'){
                        location.reload();
                    } else{
                        this.startLoginsTimer();
                    }
                }
            }, 1000);
        },

        async checkIfLoginIsValid(){
            return axios.get(route('counter.validateLogin')).then((response) => {
                this.loginStatus = response.data;
                return 'ok';
            });
        },


        //CAMERA TIMER
        startCamerasTimer() {
            clearInterval(this.camerasTimer);

            function pad(val) {
                return val > 9 ? val : "0" + val;
            }

            let sec = 0;
            this.camerasTimer = setInterval(async () => {
                if (!sec) {
                    let sec = 0;
                }

                pad(++sec % 60);

                if (sec === 2) {
                    await this.checkCameras();
                    this.startCamerasTimer();
                }
            }, 1000);
        },

        getDevice(log){
            if(log.was_admin == 1){
                return 'admin';
            } else if(log.device_id == null){
                return 'reset';
            } else{
                return log.device.username;
            }
        },

        async checkCameras(){
            return axios.get(route('counter.checkCameras', this.site)).then(() => {
                return 'ok';
            });
        },
    }
};
</script>
