require('dotenv').config()

window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

window.axios = require('axios');
//window.Swal = require('sweetalert2');

//require('./messages.js');

window.momentJS = require('moment');

Lang.setLocale($('html').prop('lang'));
momentJS.locale($('html').prop('lang'));

window.fullscreenvar = 0;

require('./sweetAlertCustom.js');

import Echo from "laravel-echo"
window.io = require('socket.io-client');
const WS_ENDPOINT = process.env.MIX_WS_ENDPOINT || "socket.io";
// const WS_HOST = 'https://services.afluencia.io';
const WS_HOST = process.env.MIX_WS_HOST || 'https://services.afluencia.io';

window.Echo = new Echo({
    broadcaster: WS_ENDPOINT,
    host: WS_HOST // this is laravel-echo-server host
});

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('./routes/index.js')

require('./vue.js')

require('./notifications')

//HAMBURGUER FUNCTION
$('.hamburger').click(function () {
    $(this).toggleClass('is-active');
    $('.navSidebar').toggleClass('open');

    if($(this).hasClass('is-active')){
        lockScroll();
    } else{
        unlockScroll();
    }
});

function lockScroll(){
    $html = $('html');
    $body = $('body');
    var initWidth = $body.outerWidth();
    var initHeight = $body.outerHeight();

    var scrollPosition = [
        self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
        self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    ];
    $html.data('scroll-position', scrollPosition);
    $html.data('previous-overflow', $html.css('overflow'));
    $html.css('overflow', 'hidden');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    var marginR = $body.outerWidth()-initWidth;
    var marginB = $body.outerHeight()-initHeight;
    $body.css({'margin-right': marginR,'margin-bottom': marginB});
}



function unlockScroll(){
    $html = $('html');
    $body = $('body');
    $html.css('overflow', $html.data('previous-overflow'));
    var scrollPosition = $html.data('scroll-position');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    $body.css({'margin-right': 0, 'margin-bottom': 0});
}

$("[data-toggle=searchModal]").click(function () {
    $('.sideModal').toggleClass('open');
});

window.goBack = function () {
    window.history.back();
}

window.upgradeSwal = function() {
    Swal.fire({
        title: 'Limite atingido',
        text: 'Deseja alterar o seu plano?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#1fc4c7',
        cancelButtonColor: '#ff5050',
        confirmButtonText: 'Atualizar plano',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.value == true) {
            window.location.href = route('plans.choosePlan');
        }
    })
}

window.setTimeout(function() {
    $(".alert").fadeTo(500, 0).slideUp(500, function(){
        $(this).remove();
    });
}, 100000);
